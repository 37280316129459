import React from "react";
import { signIn, signOut, useSession } from "next-auth/react";
import { Button } from "@components/button";
import useTranslation from "next-translate/useTranslation";

const AuthenticationButton = (props) => {
  const { t } = useTranslation("common");
  const { data: session, status } = useSession();

  return <div></div>;

  switch (status) {
    case "loading":
      return <div></div>;
    case "authenticated":
      return (
        <Button
          className="w-full"
          onClick={() => signOut({ callbackUrl: "/" })}
          appearance="secondary"
        >
          {t("sign-out")}
        </Button>
      );
    case "unauthenticated":
      return (
        <Button
          className="w-full"
          onClick={() => signIn()}
          appearance="primary"
        >
          {t("sign-in")}
        </Button>
      );
  }
};

export default AuthenticationButton;
