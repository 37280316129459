import React, { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@components/icons";
import { BehaleIcon } from "@components/icons";
import Link from "next/link";
import Head from "next/head";
import classNames from "classnames";
import { signIn, signOut, useSession } from "next-auth/react";
import { Button } from "@components/button";
import useTranslation from "next-translate/useTranslation";
import AuthenticationButton from "@components/AuthenticationButton";

const additionalNavigation = [
  {
    name: "Wiki 🍃",
    href:
      "https://behale.notion.site/Behale-Wiki-4cfb46aa92a0466787a4460d42c5ee52",
  },
];

type INavigationItem = {
  key: string;
  name: string;
  href: string;
  current: boolean;
};

const profile = ["Your Profile", "Settings", "Sign out"];

type IAppShell = {
  name?: string;
  navigation?: INavigationItem[];
  avatarId?: string | null;
  headTitle?: string;
  currentPageKey?: string;
};

export const AppShell: React.FC<IAppShell> = ({
  children,
  avatarId = null,
  currentPageKey = "",
  ...props
}) => {
  const { t } = useTranslation("common");
  const { data: session, status } = useSession();

  const navigation = [
    {
      key: "sessions",
      href: `/app/avatars/${avatarId}`,
    },
    {
      key: "specialists",
      href: `/app/avatars/${avatarId}/specialists`,
    },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      {(props.headTitle || props.name) && (
        <Head>
          <title>{props.headTitle || props.name}</title>
        </Head>
      )}
      <Disclosure as="nav" className="sticky top-0 z-50 bg-primary-dark">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16 border-b border-opacity-25 elative lg:border-b">
                <div className="flex items-center justify-between w-full h-16 px-4 lg:justify-start sm:px-0">
                  <div className="flex flex-shrink">
                    <a href={avatarId ? `/app/avatars/${avatarId}` : "/"}>
                      <div className="flex-shrink-0 cursor-pointer">
                        <span className="sr-only">Behale</span>
                        <BehaleIcon fill="white" className="w-8 h-8" />
                      </div>
                    </a>
                  </div>
                  <div className="flex-grow hidden md:block md:ml-10">
                    <div className="flex space-x-4">
                      {navigation &&
                        navigation.map((item) => (
                          <a
                            href={item.href}
                            key={item.href}
                            aria-current={
                              item.key === currentPageKey ? "page" : undefined
                            }
                          >
                            <span
                              className={classNames(
                                item.key === currentPageKey
                                  ? "bg-primary-darker text-white"
                                  : "text-white hover:bg-primary-darker hover:bg-opacity-75",
                                "rounded-md py-2 px-3 text-sm font-medium"
                              )}
                            >
                              {t(`shell.navigation.${item.key}`)}
                            </span>
                          </a>
                        ))}
                    </div>
                  </div>
                  {/* External links (including Wiki) */}
                  <div className="flex-shrink hidden md:block">
                    <div className="flex space-x-4">
                      {additionalNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          target="_blank"
                          className={classNames(
                            "text-white hover:bg-primary-darker hover:bg-opacity-75",
                            "rounded-md py-2 px-3 text-lg font-medium"
                          )}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="flex items-center ml-4 md:ml-6">
                      {/* <button className="p-1 text-gray-100 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="w-6 h-6" aria-hidden="true" />
                      </button> */}
                      <AuthenticationButton />

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="flex items-center max-w-xs text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                {profile.map((item) => (
                                  <Menu.Item key={item}>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        {item}
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </div>
                  {/* Mobile menu button */}
                  <div className="flex -mr-2 md:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 text-white bg-transparent rounded-md hover:text-white hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-dark focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block w-6 h-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block w-6 h-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="border-b border-primary-darker md:hidden">
              <div className="px-2 py-3 space-y-1 border-b border-gray-200 border-opacity-25 sm:px-3 ">
                {navigation.map((item, itemIdx) => (
                  <Link
                    href={item.href}
                    key={item.href}
                    aria-current={item.key === currentPageKey ? "page" : undefined}
                  >
                    <span
                      className={classNames(
                        item.key === currentPageKey
                          ? "bg-primary-darker text-white"
                          : "text-white hover:bg-primary-darker hover:bg-opacity-75",
                        "block text-base rounded-md py-2 px-3 font-medium"
                      )}
                    >
                      {t(`shell.navigation.${item.key}`)}
                    </span>
                  </Link>
                  // itemIdx === 0 ? (
                  //   <Fragment key={item.name}>
                  //     {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                  //     <a
                  //       key={item.name}
                  //       target="_blank"
                  //       href={item.href}
                  //       className="block px-3 py-2 text-base font-medium text-white rounded-md bg-primary-darker"
                  //     >
                  //       {item.name}
                  //     </a>
                  //   </Fragment>
                  // ) : (
                  //   <a
                  //     key={item.name}
                  //     target="_blank"
                  //     href={item.href}
                  //     className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                  //   >
                  //     {item.name}
                  //   </a>
                ))}
              </div>
              <div className="px-2 py-3 space-y-1 sm:px-3">
                {additionalNavigation.map((item, itemIdx) =>
                  itemIdx === 0 ? (
                    <Fragment key={item.name}>
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <a
                        key={item.name}
                        target="_blank"
                        href={item.href}
                        className="block px-3 py-2 text-base font-medium text-white rounded-md"
                      >
                        {item.name}
                      </a>
                    </Fragment>
                  ) : (
                    <a
                      key={item.name}
                      target="_blank"
                      href={item.href}
                      className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </a>
                  )
                )}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <AuthenticationButton />
                  {/* <div className="flex-shrink-0">
                      <img
                        className="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        Tom Cook
                      </div>
                      <div className="text-sm font-medium leading-none text-gray-400">
                        tom@example.com
                      </div>
                    </div>
                    <button className="flex-shrink-0 p-1 ml-auto text-gray-400 bg-gray-800 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="w-6 h-6" aria-hidden="true" />
                    </button> */}
                </div>
                {/* <div className="px-2 mt-3 space-y-1">
                    {profile.map((item) => (
                      <a
                        key={item}
                        href="#"
                        className="block px-3 py-2 text-base font-medium text-gray-400 rounded-md hover:text-white hover:bg-gray-700"
                      >
                        {item}
                      </a>
                    ))}
                  </div> */}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="pb-32 bg-primary-dark">
        <header className="py-5">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            {props.name && (
              <h1 className="text-2xl font-bold text-white">{props.name} </h1>
            )}
          </div>
        </header>
      </div>

      <main className="flex flex-col flex-grow -mt-32">
        <div className="flex-grow w-full h-full px-4 pb-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {children}
        </div>
      </main>
      <footer>
        <div className="max-w-3xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="py-8 text-sm text-center text-gray-500 border-t border-gray-200 sm:text-left">
            <span className="block sm:inline">&copy; 2021 Behale.</span>{" "}
            <span className="block sm:inline">All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  );
};
